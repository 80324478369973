import React from "react"

import Layout from "../components/layout"

const ContactPage = () => {
  return (
    <Layout>
      <h1>Contact</h1>
      <p>
        The best way to reach me is via{" "}
        <a href="https://www.facebook.com/NinnaMoss/" target="_blank">
          Facebook.com
        </a>{" "}
      </p>
    </Layout>
  )
}

export default ContactPage
